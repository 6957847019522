.cardContainer {
    margin-bottom: 15px;
    background-color: white;
    border-radius: 10px;
    color: black;
    margin: 0px 0px;
    padding: 20px 10px 20px 10px;
  }
  .cardContainer:hover{
    cursor:pointer
  }
  .col-11 {
    display: flex;
    align-items: center;
  }
  
  .index {
    margin-right: 10px;
  }
  
  .url {
    margin-right: 10px;
    flex: 1;
  }
  
  .progress {
    margin-right: 10px;
    color: white !important;
  }
  
  .icon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  
  @media screen and (max-width:1000px){
    .index, .url{
        font-size: 12px;
    }
  }