.claim_your_offer_text {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.73px;
  text-align: center;
  color: #007eb2;
  margin-bottom: 24px;
  margin-top: 30px;
}
.redeem_serial_text{
    font-size: 32px;
    font-weight: 600;
    line-height: 38.73px;
    text-align: center;
    color: #007eb2;
    margin-bottom: 24px;
}
.serial-input{
    height: 44px;
}
.serial-form{
    font-weight: 600;}

.serial-pricing-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px; 
    border: 1px solid #ddd; 
    border-radius: 8px; 
    background-color: #fff; 
    height: 500px;
    margin-bottom: 30px;
}

.serial-pricing-card img {
  max-width: 100%;
}

.serial_code_pricing-name {
  text-align: center;
  font-size: 26px; 
  font-weight: bold; 
  color: rgba(0, 126, 178, 1);
}
.serial-pricing-amount {
  font-weight: 700;
  color: rgba(255, 152, 30, 1);
  font-size: 48px;
  margin-bottom: 0px;
}
.serial-info-text {
  margin: 0; 
  color: rgba(144, 144, 144, 1);
}
.serial-info-text-upper {
  margin: 0; 
  color: rgba(144, 144, 144, 1);
  margin-bottom: 24px;
}
.serial-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.serial-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
  color: black;
}

.serial-info-item i {
  margin-right: 10px; 
}
.pricing-plans-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  

  
  .discount-badge {
    position: absolute;
    top: 5px;
    right: -55px;
    background-color: rgba(255, 152, 30, 1);
    color: #fff;
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 14px;
  }
  @media screen and (max-width: 450px) {
  
    .redeem_serial_text{
        font-size: 24px !important;
    }
    .claim_your_offer_text{
        
        font-size: 24px !important;
    }
}
.serial-get-started-button {
    padding: 10px 20px;
    width: 100%;
    border: none;
    height: 48px;
    background-color: rgba(0, 126, 178, 1);
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 24px;
  }
  .home_page_btn_filled:hover {
    background-color: rgba(0, 113, 159, 1) !important;
  }
  .serial-get-started-button-yellow {
    align-self: center;
    margin-top: auto;
    padding: 10px 20px;
    width: 100%;
    border: none;
    background-color: rgba(255, 152, 30, 1);
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    height: 48px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .serial-get-started-button-yellow:hover {
    background-color: rgba(232, 127, 2, 1) !important;
  }

  @media screen and (max-width: 490px){
    .serial-get-started-button-yellow{
        height: auto;
    }
    .serial-get-started-button{
        height: auto;
    }
  }