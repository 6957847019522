.projectName {
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
}

.icon {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  color: white;
}

.editBtn {
  background-color: transparent;
  border: none;
  margin-right: 10px;
  filter: none;
  color: white;
}
.menu {
  padding-left: 0px;
}
@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .menu {
    padding-left: 15px !important;
  }
}
.inputTitles {
  color: black;
  font-weight: 500;
  margin: 0px;
}
.menu_inner {
  background-color: rgba(242, 247, 250, 1);
  border-radius: 10px;
  margin-top: 20px;
}
.headings {
  color: #00a1e3;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.applyBtn {
  background-color: #00a1e3;
  color: white;
  font-weight: 700;
  border: none;
}
@media screen and (max-width: 500px) {
  .editBtn {
    margin-right: 5px;
    padding: 5px;
  }
  .applyBtn {
    padding: 5px;
  }
}

@media screen and (max-width: 1050px) {
  .inputTitles {
    font-size: 12px;
  }
}
.colorField {
  width: fit-content; 
  margin-top: 10px; 
}
.tab .ant-tabs-nav {
  background-color: aqua !important;
}
.btn_outlined:hover {
  background-color: rgba(0, 126, 178, 1) !important;
  color: white !important;
}
.btn_outlined {
  margin-right: 15px;
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 126, 178, 1);
  background-color: transparent;
  color: rgba(0, 126, 178, 1);
  font-weight: 600;
}
.btn_filled {
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  height: 45px;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  border: none;
  font-weight: 600;
}
.btn_filled:hover {
  color: white !important;
  background-color: rgb(3, 113, 160) !important;
}
.tab {
  font-weight: 600;
  color: rgba(0, 126, 178, 1);
}
.tabActive {
  background-color: #00a1e3;
}
.inputFields {
  margin-bottom: 13px;
}
.button {
  background-color: transparent;
  border: 0.5px solid #00a1e3;
  color: #00a1e3;
}

.mobileButton,
.laptopButton {
  font-size: 20px;
  background-color: transparent;
  margin: 10px 0px;
}

.fixedDimensions {
  border: 1px solid #00a1e3;
  border-radius: 5px;
  padding: 0px;
}

.segmentedContainer {
  width: 100%;
  text-align: center;
  background-color: transparent;
}
.segmentedContainer .ant-segmented-item-label {
  background-color: transparent;
}

.fixedDimensions:hover,
.laptopButton:hover {
  cursor: pointer;
}
.backButton {
  color: white;
  background-color: transparent !important;
  border: none;
}
.backButton:hover {
  cursor: pointer;
}
