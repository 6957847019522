

.FacebookLoginButton {
    height: 40px !important;
    width: 175px !important;
    font-size: 14px !important;
    margin: 0px !important;
}

  
  .customFacebookLoginButton {
    background-color: #0c4590;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0px 10px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    height: 40px;
    width: 210px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }
  
  .customFacebookLoginButton:hover {
    background-color: #165fbb;
  }
  
