.countdownContainer {
  display: flex;
  align-items: center;
}
.innerContainer {
  color: white;
  font-weight: 600;
  height: 230px;
  font-size: 20px;
}
.backButton {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 126, 178, 1);
}
.statusContainer {
}
.projectContainer {
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(201, 201, 201);
}
.icons {
  font-size: 16px;

  color: black;
}

.btn_filled {
  margin-left: 15px;
  margin-top: 10px;
  padding: 5px 80px;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  border: none;
  font-weight: 600;
}

.blur_container {
  position: relative;
  transition: all 0.3s ease;
}

.blur_container:hover .blur_content {
  filter: blur(2px);
  cursor: pointer;
  opacity: 0.7; 
}

.blur_container:hover .blur_button {
  opacity: 1;
  pointer-events: auto;
}

.blur_content {
  transition: all 0.3s ease;
}

.blur_button {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none; 
  transition: all 0.3s ease;
  width: 85%;
  padding: 5px 40px;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  border: none;
  height: 44px;
  font-weight: 600;
  border-radius: 5px;
}
.blur_button:hover {
  background-color: rgba(0, 113, 159, 1) !important;
}
.projectName {
  margin-bottom: 0px;
  margin-top: 0px;
  color: black;
  font-size: 20px;
}

.statusText {
  color: rgb(24, 24, 24);
  font-weight: 500;
}
.countdownContainer {
  color: rgb(20, 20, 20);
  font-weight: 300;
}
.countdownText {
  font-size: 12px;
  font-weight: 300;
  color: black;
}
.countdownContainer {
  margin-bottom: 20px;
}
.projectButton {
  background-color: var(--lighterBlue);
  color: white;
  font-weight: 500;
  padding: 5px 20px;
  border: none;
  border-radius: 10px;
}
.countdown {
  font-size: 14px;
}

.last_edited {
  color: black;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
}
@media screen and (max-width: 1550px) {
  .statusText {
    font-size: 12px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1550px) {
  .blur_button {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .blur_button {
    font-size: 16px;
  }
}
@media screen and (max-width: 380px) {
  .blur_button {
    font-size: 16px;
  }
}
