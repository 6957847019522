.projectContainer {
    border-radius: 20px;
    background-color: #007EB2;
    
  height: 230px;
  }
  .innerContainer{

  }
  .addProject{
    color: white;
    font-size:25px;
    font-weight: 500;
  }
  .plusIcon
  {
    color: white;
    font-size: 45px;
    margin-bottom: 20px;
  }
  .projectContainer:hover{
    cursor: pointer;
  }
  
  
@media screen and (max-width:500px){
    .addProject{
        font-size: 22px;
    }
}

