/* Overlay to dim the screen */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dimmed background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Ensure the iframe container is responsive */
.iframeContainer {
  position: relative;
  width: 90%; /* Make the width responsive */
  max-width: 860px; /* Maximum width for large screens */
  background-color: #000000;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Make iframe responsive */
.iframeContainer iframe {
  width: 100%; /* Full width of the container */
  height: auto; /* Auto height */
  aspect-ratio: 16 / 9; /* Maintain the aspect ratio */
  border-radius: 8px;
}

/* Close button styles */
.closeButton {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 1100;
}

iframe {
  border-radius: 8px;
}
