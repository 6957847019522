.mainRow {
  background-color: white;
  height: calc(100vh);
}

.mainCol {
  background-color: rgba(242, 247, 250, 1);
  border-radius: 20px;
}
.white1Btn,
.blue2Btn,
.white2Btn {
  height: 25px;
}

.headerText {
  margin-bottom: 0px;
  font-weight: 600;
}

.btnContainer {
  display: flex;
  align-items: center; 
  text-align: center;
  margin: 15px 0px;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
}
.btnContainer img,
.btnContainer p,
.btnContainer svg {
  margin-right: 10px;
}
.or {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
}
.or:after,
.or:before {
  content: "";
  display: block;
  background: rgb(255, 255, 255);
  width: 30%;
  height: 1px;
  margin: 0 10px;
}
.step {
  color: black !important;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0px;
}
.note {
  color: red;
  margin-bottom: 20px;
  font-weight: 200;
}
@media screen and (max-width: 650px) {
  .white1Btn,
  .blue2Btn,
  .white2Btn {
    height: 15px;
  }
  .headerText {
    font-size: 10px;
  }
}

.inputTitles {
  color: black;
  margin-bottom: 3px;
}
.submitBtn {
  width: 48%;
  background-color: var(--lightBlue);
  margin-top: 20px;
  margin-left: 5px;
}
.previousBtn {
  width: 48%;
  background-color: #e7f5f9;
  margin-top: 20px;

  margin-right: 5px;
}
