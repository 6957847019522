.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.68);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #00719F;
    color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    padding: 20px;
    position: relative;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #005e7a;
  }
  
  .closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modalBody {
    margin-top: 20px;
  }
  
  .modalFooter {
    margin-top: 20px;
    width: 100%;
  }
  
  .okButton {
    background-color: #ffffff;
    border: none;
    color: #00719F;
    width: 100%;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
  }
  