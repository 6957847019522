.feature-component-card {
  background-color: rgb(255, 255, 255);
  padding: 35px 0px;
  margin: 0px;
  border-radius: 10px;
  height: auto;
  box-shadow: 0px 3.93px 11.56px 0px rgba(21, 21, 21, 0.05);
}
.feature-component-stars {
  color: rgba(0, 126, 178, 1);
  font-size: 24px;
}
.testimoniallikeicon{
    margin-right: 10px;
}
.verified-user-text{
    font-size: 20px;
}
.testimonial-description{
    font-size: 20px;
}
.initial-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(242, 247, 250, 1); 
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 12px; 
}

.feature-component-name {
  font-weight: 600;
}

.faq_heading{
    font-weight: 600;
}
.description{
    color: black;
}

@media screen and (min-width: 768px) {
    .feature-component-card {
        height: 400px !important;
      }
}
@media screen and (min-width: 880px) {
    .feature-component-card {
        height: 380px !important;
      }
}
@media screen and (min-width: 1110px) {
    .feature-component-card {
        height: 340px !important;
      }
}
@media screen and (min-width: 1200px) {
    .feature-component-card {
        height: 430px !important;
      }
}
@media screen and (min-width: 1400px) {
    .feature-component-card {
        height: 380px !important;
      }
}

@media screen and (min-width: 1500px) {
    .feature-component-card {
        height: 350px !important;
      }
}
@media screen and (min-width: 1730px) {
    .feature-component-card {
        height: 310px !important;
      }
}
