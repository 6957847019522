.text{
    color: #007EB2;
    font-weight: 200;
}
.text strong{
    color: #007EB2;
    font-weight: 700;
}
.heading{
    color: #007EB2;
    font-weight: 600;
    font-size: 32px;
    margin-top: 20px;
}

.footer_component {
    background-color: rgba(0, 126, 178, 1);
  }

@media screen and (max-width:550px){
    .text{
        font-size: 12px;
    }
    .heading{
        font-size: 24px;
    }
}