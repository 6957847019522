.image{
    height: 60vh;
}

@media screen and (max-width:660px){
    .image{
        height: auto;
        width: 100%;
    }
    
}