.mainRow {
    background-color: white;
    height: calc(100vh - 78px);
  }
  .mainCol {
    background-color: rgba(245, 245, 245, 1);
    border-radius: 20px;
  }
  .createAccountHeading {
    color: black;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 36px;
  }
  .loginInput input {
    margin-left: 15px;
  }
  .loginPassword input {
    margin-left: 15px;
    color: black;
  }
  .or {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
  }
  
  .or:after,
  .or:before {
    content: "";
    display: block;
    background: rgb(255, 255, 255);
    width: 30%;
    height: 1px;
    margin: 0 10px;
  }
  
  .submitButton {
    width: 200px;
    background-color: #00a1e3 !important;
    color: white !important;
    margin-bottom: 20px;
  }
  .forgotPasswordBtn {
    text-align: end;
    color: #00a1e3;
    font-weight: 700;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .createAccountBtn {
    text-align: center;
    color: white;
    margin-bottom: 5px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  .resendCode{
    color: white;
  }
  .resendCodeBtn{
    font-weight: 600;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 576px) {
    .createAccountHeading {
      font-size: 24px;
    }
  }
  