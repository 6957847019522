
:root {
  --black: #111111; /* Default color */
  --darkBlue: #101827; /* Default color */
  --blue: #0e243a; /* Default color */
  --lightBlue: #00a1e3; /* Default color */
  --lighterBlue:#567cbe;
  --darkGrey: #9B9B9B; /* Default color */
  --semiDarkGrey:#dedede
  --grey: #D3D3D3; /* Default color */
  --white: #efefef; /* Default color */
  --superWhite: #ffffff; /* Default color */
  --danger: #880000; /* Default color */
  --success: #00bc00; /* Default color */
}
/* 


.return_to_campaign:hover{
    background-color: rgba(0, 126, 178, 1)    !important;
    color: white !important;
}
.home_page_btn_filled_touch:hover {
    background-color: rgba(0, 113, 159, 1) !important;
  }

  .coupon-input-btn button:hover {
    background-color: rgba(232, 127, 2, 1)    ;
  } 
    
  
  */