.menu {
  height: 110vh;
  background-color: #007eb2;
  width: 50px;
  color: white;
  padding: 6px;
}

.menu-item {
  color: white !important; 
  border: none !important;
}

.menu-item:hover {
  color: white; 
}

.ant-menu-item {
  color: white; 
  padding: 5px !important;
  width: 35px !important;
  border: none !important;
  margin-bottom: 8px !important;
  margin-top: 12px !important;
}

.ant-menu-item-selected {
  background-color: #005f6b; 
  color: white; 
  padding: 5px !important;
  width: 35px !important;
}

.logout_btn {
  background-color: #007eb2;
  display: flex;
  border: none;
  position: fixed;
  width: 40px !important;
  bottom: 20px;
  left: 0px;
  height: 40px !important;
}
.logoutButton:hover {
  background-color: #007eb2;
}
.logout_btn:hover {
  background-color: #007eb2 !important;
  cursor: pointer;
}

.logout_btn:hover {
  background-color: rgb(42, 113, 155);
}

@media screen and (min-width: 1200px) {
  .ant-menu {
    width: 256px !important;
  }

  .ant-menu-item {
    color: white;
    padding: 5px !important;
    width: 250px !important;
  }

  .ant-menu-item-selected {
    width: 220px !important;
  }

  .logoutButton {
    width: 220px !important;
  }

  .logout_btn {
    background-color: #007eb2;
    bottom: 20px;
    left: 0px;
    width: 220px !important;
  }

  .logoutButton {
    width: 30px;
  }
}

.navigator_modals {
  color: white;
  padding: 5px;
  margin: 4px;
  margin-bottom: 8px;
  margin-top: 12px;
  cursor: pointer;
}
.navigator_modals img {
  margin-right: 8px;
}
.ant-menu-title-content {
  color: white;
}
.modal-heading {
  font-weight: 600;
  text-align: center;
  color: rgb(42, 113, 155);
}
