.homeMainImage {
  width: 80%;
}
.home_page_image_1 {
  width: 90%;
  object-fit: cover;
  border-radius: 15px;
  border: none;
}
.footer_component {
  background-color: rgba(0, 126, 178, 1);
}
.home_page_btn_outlined {
  margin-top: 10px;
  padding: 5px 5px;
  border: 1px solid rgba(0, 126, 178, 1);
  background-color: white;
  height: 45px !important;
  border-radius: 4px;
  width: 100%;
  color: rgba(0, 126, 178, 1) !important;
  font-weight: 600;
}
.home_page_btn_outlined:hover {
  background-color: rgba(0, 126, 178, 1);
  color: white !important;
}
.home_page_btn_filled {
  margin-top: 10px;
  padding: 5px 5px;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 45px !important;
  font-weight: 600;
}
.home_page_btn_filled:hover {
  background-color: rgba(0, 113, 159, 1) !important;
}

.homeSectionPTagLight {
  font-weight: 300 !important;
  color: rgba(144, 144, 144, 1) !important;
}
.checkmarkSpacing {
  display: inline-block;
  margin-right: 20px;
}
.homeTextSpan3 {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 59px;
  margin: 30px 0px !important;
  line-height: 35px;
}
.homeTextSpan1 {
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
}
.homeTextSpan2 {
  color: rgba(0, 126, 178, 1);
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
}
.homeContainer {
  padding: 60px 0px 0px 0px;
}
.homeTextPTag {
  color: white;
  font-size: 24px;
}
.homeSectionPTag {
  color: black;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.006em;
  text-align: center;
}
.cardDescription li {
  padding: 0px;
  margin-top: 5px;
  list-style: none;
}
.cardDescription li img {
  margin-right: 8px;
  height: 19px;
}
.cardDescription,
.cardHeading {
  color: black;
}
.joinForFreeBtn {
  border: 1px solid var(--lightBlue);
  background-color: transparent;
  padding: 5px 30px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
}

@media screen and (max-width: 500px) {
}
@media screen and (max-width: 990px) {
  .homeMainImage {
    width: 70%;
  }
  .homeContainer {
    text-align: center !important;
  }
}
.homeSectionPTagSmall {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.homeSectionPTag {
  font-size: 22px !important;
}
@media screen and (max-width: 1350px) {
  .homeTextSpan3 {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 500px) {
  .homeTextSpan3 {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 660px) {
  .home_page_btn_outlined,
  .home_page_btn_filled {
    width: 90vw;
  }
}

@media screen and (min-width: 660px) {
  .home_page_btn_outlined,
  .home_page_btn_filled {
    margin-left: 15px;
    width: 300px;
  }
}

.cardBody {
  background-color: white;
  text-align: center;
  padding: 20px 8px;
  border-radius: 15px;
}
.cardImage {
  width: 100%;
  margin: 20px 0px;
  max-width: 400px;
}
.cardHeading {
  font-weight: 600;
  font-size: 20px;
}
