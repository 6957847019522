.footer-logo {
  width: 100px;
}
.footer-logo-heading {
  color: white;
  margin-top: 30px;
}
.footer-item {
    color: rgba(255, 255, 255, 0.75) !important;
  list-style: none;
  border: none;
  line-height: 40px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
.footer-item-contact {
    color: white;
  list-style: none;
  border: none;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
.footer-item-contact a {
    color: white;
}
.footer-item a {
    color: white;
}
.footer-item{
    
  color: rgba(255, 255, 255, 0.75) !important;
}
.top-move-icon {
  background-color: white;
  border-radius: 50%;
  height: 42px;
}
.footer-item-bottom {
  color: white;
  list-style: none;
  border: none;
  font-weight: 200;
  border-top: 0.1px solid white;
  padding-top: 15px;
  padding-bottom: 0px;
}
.footer-item:hover {
  cursor: pointer;
  color: rgba(211, 211, 211, 1) !important; 
}

@media screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }
  .footer-item {
    text-align: center;
  }
  .footer-item-contact {
    text-align: center;
    margin-bottom: 10px;
  }
}
