.filterByDateOption {
  color: white;
  margin-bottom: 3px;
}
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.filterIcon {
  color: white;
}
.filter input::placeholder,
.filter span {
  color: rgb(136, 189, 213) !important;
}

.sort_dropdown {
  margin: 10px 0px 10px 0px !important;
  
  
  border-radius: 30px;
  text-align: center;
  font-weight: 600;
  color: rgba(0, 126, 178, 1);
  background-color: white !important;
  border: 1px solid rgba(0, 126, 178, 1);
  align-self: center !important;
  width: 310px;
  z-index: 1000;
  font-weight: 600;
}
.sort_dropdown p {
  margin: 0px;
  padding: 8.5px 0px;
  margin-top: 0px;
}
.sort_dropdown:hover {
  cursor: pointer !important;
}
.divider {
  border: 1px solid rgba(0, 0, 0, 0.067);
  width: 100%;
}
.btn_filled {
  margin: 10px 10px 10px 0px !important;
  padding: 5px ;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  width: 310px;
  border-radius: 4px;
  height: 44px;
  border: none;
  font-weight: 600;
}

.btn_filled:hover {
  background-color: rgba(0, 113, 159, 1) !important;
}
.title{
    color: rgba(0, 126, 178, 1);
    font-weight: 600;
    font-size: 30px;
}

.projects_row{
    position: absolute;
    width: calc(100vw - 48px);
    top: 220px;
    left: 48px;
}
@media screen and (min-width:768px){
   
.projects_row{
    position: absolute;
    width: calc(100vw - 48px);
    top: 145px;
    left: 48px;
}
    
}
@media screen and (min-width:1200px){
   
    .projects_row{
        position: absolute;
        width: calc(100vw - 270px);
        top: 145px;
        left: 256px;
    }
        
    }
    @media screen and (min-width:1350px){
   
        .projects_row{
            position: absolute;
            width: calc(100vw - 270px);
            top: 90px;
            left: 256px;
        }
            
        }
@media screen and (max-width:500px){
    .title{
        font-size: 22px;
    }
    
}
@media screen and (max-width:1350px){
  .sort_dropdown{
    width: 100% !important;
  }
  .btn_filled{
    width: 100%!important;
  }
  .btn_filled_parent{
    width:100% !important;
  }
  .filter{
    display: block !important;
  }
}