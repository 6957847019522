
.GoogleLoginButton {
}

  
.googleLoginWrapper {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .signInText {
    margin-left: 8px;
  }