.navbarLogo {
  width: 33px;
  margin-left: 30px;
}
.navItems {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
}
.navbar {
  padding: 0px;
}
.profileIcon {
  margin-right: 20px;
  font-size: 26px;
}
.navbar_toggled_options li{
    color: black !important;
    font-size: large;
}
.navbar_toggled_options li {
    display: block;
    padding: 30px 15px;
    margin-bottom: 5px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
.btnStyles {
  margin-left: 1rem;
  margin-top: 0px;
  margin-right: 15px;
  color: white;
}
.btnStyles:hover {
  font-weight: 500;
  color: white;
  cursor: pointer;
  text-decoration: underline;
}
.tryTool {
  background-color: #ff981e !important;
}
.tryTool:hover {
  background-color: rgba(232, 127, 2, 1) !important;
}
.tryToolButton {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  margin-left: 1rem;
}

.navbar {
  background-color: #007eb2;
  height: 60px;
  border-radius: 30px;
  z-index: 2000;
}
.navbarItem {
  color: var(--white);
  margin-left: 50px;
  border: none;
}
.navbarItem p {
  color: var(--white) !important;
}
.navbarTogglerIcon {
  background-image: url("../../assets/navbarTogglerIcon.svg") !important;
}
.navbarToggler {
  border: none !important;
}
.navbarToggler:active {
  border: none !important;
}
.hidden_element{
    height: 104px;
}
@media screen and (max-width: 990px) {
  .profileIcon {
    display: none;
    margin-right: 20px;
  }
  .hidden_element{
    height: 60px;
}
}
@media screen and (max-width: 992px) {
  .navbar {
    background-color: #007eb2;
    margin: 0px;
    border-radius: 0px;
  }
}
@media screen and (min-width: 1600px) {
  .navbar {
    width: 1600px !important;
    margin: auto;
    margin-top: 37px;
  }
}
@media screen and (max-width: 360px) {
  .tryTool {
    font-size: 10px;
    margin-right: 5px;
    padding: 5px !important;
  }
  .profileIcon {
    font-size: 18px;
    margin-right: 10px;
  }
  .navbarTogglerIcon {
    width: 20px;
  }
}

