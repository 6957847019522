.planCard {
  background-color: #0e243a;
  padding: 40px 20px;
  border-radius: 10px;
  color: white;
  margin-bottom: 20px;
}
.planCard button {
  background-color: transparent;
  border-color: #00a1e3;
  font-weight: 700;
  padding: 5px 20px;
  width: 100%;
  color: white;
  margin-top: 30px;
  border-radius: 5px;
}
.footer_component {
  background-color: rgba(0, 126, 178, 1);
}
.remaining-tokens {
  background-color: rgba(242, 247, 250, 1);
  border: none;
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  margin: 0px;
}
.available-token-number {
  color: rgba(255, 152, 30, 1);
  font-weight: 600;
}
.planCard h4 {
  font-weight: 700;
}
.productBox {
  margin-top: 30px;
}
.discounted-price {
  font-size: 30px;
  text-decoration: line-through;
  color: #a2a2a2;
  margin-right: 5px;
}
.coupon-input input {
  width: 100%;
  padding: 5px 0px;
  text-align: center;
  border-radius: 4px;
  height: 44px;
}
.have-a-discount-code {
  text-align: center;
  text-decoration: underline;
}
.discount-code-applied {
  color: white;
  text-align: center;
}
.discount-code-applied-yellow {
  color: rgba(255, 152, 30, 1) !important;
  text-align: center;
}
.have-a-discount-code:hover {
  cursor: pointer;
}
.coupon-input-btn button {
  background-color: rgba(255, 152, 30, 1);
  width: 100%;
  padding: 5px 0px;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  margin-bottom: 0px;
  height: 44px;
}
.coupon-input-btn button:hover {
    background-color: rgba(232, 127, 2, 1)    ;
  }
.pricing-main-inner {
  background-image: url("../../assets/pricing.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10px;
  border-radius: 40px;
}
.beta-pricing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 10px 0px;
}

.beta-pricing-text {
  color: white;
  font-weight: 600;
  font-size: 14px;
  background-color: rgba(255, 152, 30, 1);
  border-radius: 15px;
  padding: 5px 10px;
}
.transparent-pricing-text {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: black;
}
.transparent-pricing-text-secondary {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: black;
}

.pricing-container {
  text-align: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
}
.pricing-checkbox {
  background-color: rgba(0, 126, 178, 1) !important;
}
.is-label-active {
  color: black !important;
}
.is-discount-note-active {
  color: rgba(255, 152, 30, 1) !important;
}
.discount-note {
  color: rgba(216, 54, 0, 0.3);
  font-weight: 600;
}
.pricing-label {
  color: rgba(33, 33, 33, 0.3);
}
.pricing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px; 
  border: 1px solid #ddd;
  border-radius: 8px; 
  background-color: #fff; 
  height: 500px;
  margin-bottom: 30px;
}

.pricing-card img {
  max-width: 100%; 
}
.pricing-image {
  width: 150px;
}

.icon-class {
  color: #00a1e3;
  background-color: #f0efef;
  padding: 5px;
  border-radius: 100%;
}
.add-more-tokens {
  background-color: rgba(0, 126, 178, 1);
  color: white;
  font-weight: 500;
  border: none;
 
  padding: 10px ;
  width: 100%;
  border-radius: 4px;
}
.add-more-tokens:hover{
    background-color: rgba(0, 113, 159, 1)  !important;
    }

.heading-text {
  text-align: center;
  font-size: 36px; 
  font-weight: bold;
  color: black;
}
.heading-text-2 {
  text-align: center;
  font-size: 16px; 
  color: rgba(144, 144, 144, 1)  ;
}
.pricing-name {
  text-align: center;
  font-size: 26px; 
  font-weight: bold; 
  margin: 20px 0px 0px 0px; 
  color: rgba(0, 126, 178, 1);
}

.info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
  color: black;
}

.info-item i {
  margin-right: 10px; 
}

.info-text {
  margin: 0;
  color: rgba(144, 144, 144, 1)  ;
}

.get-started-button {
  align-self: center;
  margin-top: auto;
  padding: 10px 20px;
  width: 100%;
  border: none;
  background-color: rgba(0, 126, 178, 1);
  font-weight: 600;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.active-button {
  align-self: center;
  margin-top: auto;
  padding: 10px 20px;
  width: 100%;
  border: none;
  background-color: rgba(255, 152, 30, 1);
  font-weight: 600;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.get-started-button:hover {
    background-color: rgb(1, 112, 160);
}


@media screen and (max-width: 570px) {
  .transparent-pricing-text {
    font-size: 26px;
  }
  .transparent-pricing-text-secondary {
    font-size: 16px;
  }
  .beta-pricing-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 1500px) and (min-width: 992px) {
    .beta-pricing-text{
        font-size: 10px;
    }
}



.pricing-amount {
  font-weight: 700;
  color: rgba(255, 152, 30, 1);
  font-size: 48px;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .coupon-input-btn button {
    background-color: rgba(255, 152, 30, 1);
    width: 100%;
    padding: 5px 0px;
    color: white;
    font-weight: 500;
    border: none;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}


@media screen and (min-width:1400px) and (max-width:1500px){
    .coupon-input-btn{
        font-size: 12px;
    }
}