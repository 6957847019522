.mainRow {
  background-color: white;
  height: calc(100vh - 78px);
}
.mainCol {
  background-color: #f5f5f5;
  border-radius: 20px;
}
.submitButton {
  width: 200px;
  background-color: #00a1e3 !important;
  color: white !important;
  margin-bottom: 20px;
}
.customModal .ant-modal-content {
  background-color: black !important;
  color: white !important;
}

.customModal .ant-modal-header {
  background-color: black !important;
  color: white !important;
}

.customModal .ant-modal-footer {
  background-color: black !important;
  color: white !important;
}

.customModal .ant-btn-primary {
  background-color: #1890ff !important; 
  border-color: #1890ff !important;
}
.resendCode {
  color: white;
}
.socialLogin {
  text-align: center !important;
  margin-bottom: 20px;
}
.resendCodeBtn {
  font-weight: 600;
  margin-bottom: 30px;
}
.createAccountHeading {
  color: rgb(21, 21, 21);
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 30px;
}
.loginInput input {
  margin-left: 15px;
}
.loginPassword input {
  margin-left: 15px;
  color: black;
}
.or {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(74, 74, 74);
}

.or:after,
.or:before {
  content: "";
  display: block;
  background: rgb(74, 74, 74);
  width: 30%;
  height: 1px;
  margin: 0 10px;
}
.formItem {
  margin-bottom: 10px !important;
}
.formItem2 {
  margin-bottom: 5px !important;
}
.home_page_btn_filled {
  margin-top: 10px;
  padding: 5px 80px;
  width: 100% !important;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  border: none;
  height: 45px !important;
  font-weight: 600;
}
.home_page_btn_filled:hover {
  background-color: rgba(0, 113, 159, 1) !important    ;
  color: white !important;
}
.signinImage {
  width: 100%;
}
.forgotPasswordBtn {
  text-align: start;
  color: #464646;
  font-weight: 300;
  margin: 0px 0px 5px 0px;
  cursor: pointer;
}
.createAccountBtn {
  text-align: center;
  color: rgb(119, 119, 119);
  margin-bottom: 5px;
  cursor: pointer;
  margin-bottom: 15px;
}
.createAccountBtn:hover {
  color: rgb(119, 119, 119) !important;
}
@media screen and (max-width: 576px) {
  .createAccountHeading {
    font-size: 24px;
  }
}

.signinImage_parent {
  background-image: url("../../assets/Frame\ 81.svg");
  width: 100% !important;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: contain; 
  position: relative;
}

.star_component {
  color: rgba(255, 154, 30, 0.658);
  font-weight: 700;
  position: absolute;
}
.rating_text {
  position: absolute;
  font-size: 16px;
  font-weight: 275;
  line-height: 18px;
  text-align: left;
  color: rgba(144, 144, 144, 1);
}
.name_text {
  position: absolute;
  font-size: 18px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: rgba(144, 144, 144, 1);
}
.position_text {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  line-height: 8px;
  text-align: left;
  color: rgba(144, 144, 144, 1);
}

@media screen and (min-width: 1200px) {
  .signinImage_parent {
    width: 500px !important;
  }
  .star_component {
    top: 140px;
    left: 205px;
  }
  .rating_text {
    font-size: 12px;
    left: 205px;
    top: 170px;
    width: 270px;
  }
  .name_text {
    top: 320px;
    left: 205px;
    font-size: 15px;
  }
  .position_text {
    top: 340px;
    left: 205px;
    font-size: 13px;
  }
}

@media screen and (min-width: 1400px) {
  .signinImage_parent {
    width: 570px !important;
  }
  .star_component {
    top: 125px;
    left: 230px;
  }
  .rating_text {
    font-size: 14px;
    left: 230px;
    top: 155px;
    width: 300px;
  }
  .name_text {
    top: 320px;
    left: 230px;
  }
  .position_text {
    top: 344px;
    left: 230px;
  }
}

@media screen and (min-width: 1650px) {
  .signinImage_parent {
    width: 700px !important;
  }
  .star_component {
    top: 115px;
    left: 280px;
  }
  .rating_text {
    font-size: 16px;
    left: 280px;
    top: 160px;
    width: 350px;
  }
  .name_text {
    top: 320px;
    left: 280px;
  }
  .position_text {
    top: 350px;
    left: 280px;
  }
}
.ant-input::placeholder ,.ant-input{
    font-size: 16px !important;
}