.title{
    color: rgba(0, 126, 178, 1);
    font-weight: 600;
    font-size: 30px;
}

@media screen and (max-width:500px){
    .title{
        font-size: 22px;
    }
}