.mainRow {
  background-color: white;
  height: calc(100vh - 78px);
}

.mainCol {
  background-color: rgba(242, 247, 250, 1);
  border-radius: 20px;
}
.white1Btn,
.blue2Btn,
.white2Btn {
  height: 25px;
}
.whiteProgressText .ant-progress-text {
  color: white !important;
}

.headerText {
  margin-bottom: 0px;
}
.btnContainer {
  display: flex;
  align-items: center; 
  text-align: center;
  margin: 15px 0px;
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px 20px;
}
.btnContainer img,
.btnContainer p,
.btnContainer svg {
  margin-right: 10px;
}
.or {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(33, 33, 33, 1);
}
.or:after,
.or:before {
  content: "";
  display: block;
  background: rgba(33, 33, 33, 1);
  width: 40%;
  height: 1px;
  margin: 0 10px;
}
.step {
  color: black;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
}
.note {
  color: rgba(144, 144, 144, 1);
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 18px;
}
@media screen and (max-width: 520px) {
  .white1Btn,
  .blue2Btn,
  .white2Btn {
    height: 15px;
  }
  .headerText {
    font-size: 14px;
  }
  .step {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.inputTitles {
  color: black;
  margin-bottom: 3px;
  font-weight: 600;
}
.submitBtn {
  width: 100%;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  font-weight: 600;
  padding: 30px 0px;
  margin-top: 20px;
  font-size: 20px;
  margin-left: 5px;
}
.submitBtn:hover{
    background-color: rgba(0, 113, 159, 1)  !important;
    color: white !important;
    }
.previousBtn {
  width: 100%;
    margin-right: 15px;
    padding: 30px 0px;
    border: 1px solid rgba(0, 126, 178, 1);
    background-color: white;
    color: black;
    font-weight: 600;
    font-size: 20px;
    border-radius: 8px;
    color: rgba(0, 126, 178, 1);
}
.previousBtn:hover{
    color: rgba(0, 113, 159, 1) !important;
}
@media screen and (max-width: 900px) {
  .note {
    font-size: 14px;
  }
}
