.main{
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    padding: 20px ;
}
.emailCheckboxText{
    color:black !important;
    margin-left: 10px;
}
.logoutButton{
    color:  black;
    font-size: 16px;
    padding: 0px;
    margin-top: 10px;
}
