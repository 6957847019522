.startBtn {
  background-color: transparent;
  border: none;
  margin-right: 10px;
}
.projectName {
  color: black;
  font-size: 24px;
  font-weight: 700;
  margin-right: 10px;
  margin-bottom: 0px;
}
.edit_del_icon{
    cursor: pointer;
}
.download_btns {
  background-color: rgba(0, 126, 178, 1);
  color: white;
}
.statusCompleted p {
  color: rgb(0, 220, 0) !important;
  font-size: 16px;
  font-weight: 600;
}
.statusDraft p {
    color: rgba(144, 144, 144, 1)    !important;
    font-size: 16px;
    font-weight: 600;
  }
.statusProgress p {
  color: rgb(255, 145, 0) !important;
  font-size: 16px;
  font-weight: 600;
}
.statusProgress i {
  animation: rotate 2s linear infinite;
}
.btn_progress i {
  animation: rotate 2s linear infinite;
}

.btn_copylink,.btn_copylink::before,.btn_copylink:hover{
    
  margin-right: 15px;
  padding: 0px 10px !important;
  border: 1px solid rgba(16, 198, 0, 1) !important;
  background-color: white;
  color: black;
  font-weight: 400 !important;
  border-radius: 20px;
  color: rgba(16, 198, 0, 1) !important;
  }
  .btn_copylink:hover{
    cursor: pointer;
  }
.btn_outlined, .return_to_campaign{
  margin-right: 15px;
  padding: 0px 10px !important;
  border: 1px solid rgba(0, 126, 178, 1);
  background-color: white;
  color: black;
  font-weight: 400 !important;
  border-radius: 20px;
  color: rgba(0, 126, 178, 1);
}
.return_to_campaign:hover{
    background-color: rgba(0, 126, 178, 1)    !important;
    color: white !important;
}
.return_to_campaign{
    border-radius: 8px !important;
}
.btn_progress {
  margin-right: 15px;
  padding: 0px 10px !important;
  border: 1px solid rgb(248, 115, 21) !important;
  background-color: white;
  border-radius: 20px;
  color: rgb(255, 132, 44) !important;
  font-weight: 400 !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn_filled {
  margin-left: 15px;
  margin-top: 10px;
  padding: 5px 80px;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  border: none;
  font-weight: 600;
}
.video_editor{
    margin: 20px 15px 30px 0px;
    padding: 5px 80px;
    background-color: rgba(0, 126, 178, 1);
    color: white;
    border: none;
    font-weight: 600;
    width: 300px;
    height: 44px;
    border-radius: 8px;
}
.video_editor:hover{
    background-color: rgba(0, 113, 159, 1) !important;
}
.backButton {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 126, 178, 1);
}
.editBtn {
  background-color: transparent;
  border: none;
  margin-right: 10px;
  filter: none;
}
.icon {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  color: white;
}
.customModal .ant-modal.content {
  background-color: #6f00006b !important;
}
.inputTitles {
  margin-bottom: 3px;
  font-weight: 600;
}
.downloadProject {
  background-color: #00a1e3;
  color: white;
  font-weight: 600;
  border: none;
}
.loadingText {
  color: white;
  margin-left: 10px;
}

@media screen and (max-width: 700px) {
  .btn_outlined,.return_to_campaign {
    width: 100%;
    margin-right: 0px;
  }
  .btn_progress {
    width: 100%;
    margin-right: 0px;
  }
}
