.faq_heading {
  font-weight: 700;
  font-size: 36px;
  margin-top: 50px;
}
.description {
  color: rgba(71, 84, 103, 1) !important;
  font-weight: 400;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  
}
.descriptionHeading {
  font-weight: 275;
  color: rgba(71, 84, 103, 1);
  font-size: 20px;
  margin-bottom: 0px;
}

.home_page_btn_filled_touch {
  padding-left: 15px;
  margin-top: 10px;
  padding: 10px 16px;
  height: 45px !important;
  background-color: rgba(0, 126, 178, 1);
  color: white;
  border: none;
  width: auto;
  border-radius: 4px;
  max-width: 100%;
  font-weight: 600;
}
.home_page_btn_filled_touch:hover {
  background-color: rgba(0, 113, 159, 1) !important;
}
.faq-arrow {
  transition: transform 0.3s ease;
  width: 16px; 
  height: 16px;
}
.faq-arrow {
  transform: rotate(180deg); 
}
.faq-arrow.rotate {
  transform: rotate(360deg);
}

.faq-container {
  background-color: white !important; 
  padding: 20px;
}

.faq-panel {
  background-color: rgba(
    249,
    249,
    249,
    1
  ); 
  margin: 30px 0; 
  border-radius: 15px; 
  border: none;
  overflow: hidden;
  padding: 10px 24px;
  font-size: 26px;
  font-weight: 500;
  line-height: 31.47px;
  text-align: left;
}
.ant-collapse-item{
    border: none !important;
}
.cant-find-answer {
  background-color: rgba(249, 249, 249, 1);
  padding: 16px;
  width: 100%;
  gap: 0px;
  border-radius: 16px;
}
.faq-panel .ant-collapse-header {
  color: black; 
  font-family: "Inter", sans-serif !important;
}

.faq-panel.active .ant-collapse-header {
  color: rgba(0, 126, 178, 1); 
  font-weight: 500;
  padding-bottom: 0px;
}
.ant-collapse-header-text {
  font-weight: 600;
  font-family: "Inter", sans-serif !important;
}
.faq-panel p {
  margin: 0px;
  font-size: 20px !important;
}
.faq-panel.active p {
  font-size: 20px !important;
}
.faq-panel p {
  color: black; 
  font-weight: 400;
}


.ant-collapse-header .anticon {
  float: right;
  transition: transform 0.3s ease;
}

.home_page_btn_outlined {
  margin-right: 15px;
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  border: 1px solid rgba(0, 126, 178, 1);
  background-color: white;
  color: black;
  font-weight: 600;
}
.home_page_btn_outlined_white {
  margin-right: 15px;
  margin-top: 10px;
  padding: 5px;
  width: 340px !important;
  max-width: 100% !important;
  border-radius: 8px;
  height: 45px;
  border: 1px solid rgba(0, 126, 178, 1);
  background-color: white;
  color: black;
  font-weight: 600;
}
.home_page_btn_outlined_white:hover{
    background-color: rgba(255, 255, 255, 0.908);
}
.home_page_btn_outlined_white_pricing {
  margin-right: 15px;
  margin-top: 10px;
  padding: 10px 16px;
  border-radius: 4px;
  max-width: 100% !important;
  height: 45px;
  border: 1px solid rgba(0, 126, 178, 1);
  background-color: white;
  color: black;
  font-weight: 600;
}
.record_video {
  background-color: rgba(0, 126, 178, 1);
  padding: 30px;
  width: 100%;
  border-radius: 30px;
}
.record_video h5 {
  font-weight: 600;
  color: white;
  font-size: 28px;
}
.record_video p {
  font-weight: 300;
  font-size: 16px;
}
.ant-collapse {
  background-color: white !important;
}
@media screen and (max-width: 992px) {
  .home_page_btn_filled_touch {
    width: 100%;
  }
  
}
@media screen and (max-width: 768px) {
  .faq-panel {
    font-size: 16px;
    padding: 0px;
  }
}
@media screen and (min-width: 992px) {
    .cant-find-answer {
        padding: 32px;
      }
  }